export const columns = [
  {
    title: '编号',
    dataIndex: 'slug',
  },
  {
    title: '缩略图',
    scopedSlots: {
      customRender: 'thumbnail'
    }
  },
  {
    title: '创建日期',
    dataIndex: 'created_time',
    scopedSlots: {
      customRender: 'created-time'
    }
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    }
  }
];