<template>
  <div id="main-content">
    <a-row :gutter="24">
      <a-col :span="4">
        <DFSAdminNavbar active="图片管理" />
      </a-col>
      <a-col :span="20">
        <a-spin :spinning="spinning" tip="上传中，请稍后...">
          <div class="ops">
            <a-upload
              :show-upload-list="false"
              :before-upload="beforeUpload"
            >
              <a-button type="primary"><a-icon type="upload" />上传</a-button>
            </a-upload>
          </div>
          <div class="admin-content">
            <a-table
              :columns="columns"
              :data-source="pics"
              :pagination="false"
              :row-key="pic => pic.slug"
            >
              <template slot="thumbnail" slot-scope="record">
                <a :href="record.lossless" target="_blank">
                  <img :src="record.thumbnail" :style="{ height: '80px' }">
                </a>
              </template>
              <template slot="created-time" slot-scope="text">
                {{ text | moment }}
              </template>
              <template slot="action" slot-scope="record">
                <a-popconfirm
                  title="确定删除该图片吗？"
                  @confirm="onDestroy(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </template>
            </a-table>
          </div>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DFSAdminNavbar from '@/components/DFSAdminNavbar';

import { columns } from './utils';
import { list, upload, destroy } from '@/services/pic';

export default {
  name: 'Content',
  components: {
    DFSAdminNavbar,
  },
  data() {
    return {
      columns,
      species: this.$route.params.species,
      pics: [],
      spinning: false,
    };
  },
  methods: {
    fetch() {
      list(this.species).then(({ data }) => {
        this.pics = data.data;
      });
    },
    beforeUpload(file) {
      this.spinning = true;
      const formData = new FormData();
      formData.append('file', file);
      upload(this.species, formData).then(() => {
        this.spinning = false;
        this.fetch();
      }).catch(() => {
        this.$message.error('非法图片。');
        this.spinning = false;
      });
      return false;
    },
    onDestroy(pic) {
      destroy(pic.slug).then(() => {
        this.$message.info(`${pic.slug}删除成功。`);
        this.fetch();
      });
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>


<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .ops
    margin-bottom: 1rem
</style>