<template>
  <div id="admin-navbar">
    <a-menu
      mode="inline"
      :default-selected-keys="[active]"
    >
      <a-menu-item v-for="item in items" :key="item.title">
        <a :href="item.url">{{item.title}}</a>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
export default {
  name: 'DFSAdminNavbar',
  props: {
    active: String
  },
  data() {
    return {
      items: [
        {title: '分类管理', url: '/admin/taxons'},
        {title: '物种管理', url: '/admin/species'},
        {title: '用户管理', url: '/admin/users'}
      ]
    };
  }
}
</script>

<style lang="stylus" scoped>
</style>
