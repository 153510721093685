import service from '@/utils/service';

export function list(species) {
  return service({
    method: 'get',
    url: `/v1/pics/?species=${species}`
  });
}

export function upload(species, data) {
  return service({
    method: 'post',
    url: `/v1/species/${species}/pic-upload/`,
    data
  });
}

export function destroy(slug) {
  return service({
    method: 'delete',
    url: `/v1/pics/${slug}/`
  });
}
